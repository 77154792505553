.centers-list {
  margin: auto;
  overflow: scroll;
  width: 80%;

  .centers-message {
    background: $lightWhite;
    border-radius: 24px;
    margin-top: 12px;
    margin-bottom: 26px;
    padding: 12px;

    p {
      margin-bottom: 0;
    }
  }

  .card-list {
    display: flex;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 767px) {
  .centers-list {
    width: 100%;
  }
}
