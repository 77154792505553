.card {
  align-items: center;
  background-color: $white;
  border-radius: 16px;
  border: 2px solid $lightWhite;
  cursor: pointer;
  height: 180px;
  justify-content: space-between;
  margin: 6px;
  padding: 30px;
  position: relative;
  text-align: left;
  width: calc((100% / 3) - 12px);

  .data__title,
  .data__address {
    height: 40px;
    margin: 0;
    text-align: left;
    width: 100%;
  }

  .data__title {
    font-size: 16px;
  }

  .data__address {
    font-size: 14px;
    opacity: 0.5;
  }

  .card-button {
    background-color: $white;
    border-radius: 100px;
    border: 2px solid $orange;
    bottom: 32px;
    color: $orange;
    padding: 16px;
    text-align: center;
    width: 100%;

    &:hover {
      background-color: $orange;
      border: 2px solid $blackText;
      color: $blackText;
      text-decoration: none;
    }
  }
}

.card.card-selected {
  background-color: $orange;
  border: 2px solid $orange;

  .data__address {
    opacity: 0.7;
  }

  .card-button {
    border: 2px solid $blackText;
    color: $blackText;

    &:hover {
      background-color: $blackText;
      border: 2px solid $white;
      color: $white;
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .card {
    height: 200px;
  }
}

@media only screen and (max-width: 900px) {
  .card {
    height: 180px;
    padding: 20px;
    width: calc((100% / 2) - 12px);
  }
}

@media only screen and (max-width: 600px) {
  .card {
    width: calc((100% / 1) - 12px);
  }
}

@media only screen and (max-width: 320px) {
  .card {
    height: 300px;
  }
}
