.filter-content__data {
  .filter-form {
    background-color: $white;
    padding: 24px;
    width: 80%;
    border: 2px solid $lightWhite;
    border-radius: 16px;
    margin: -100px auto 20px;

    .filter-form__title {
      font-size: 24px;
      font-style: normal;
      margin-bottom: 12px;
    }

    .filter-form__content {
      display: flex;
      justify-content: center;
    }

    .filters__input {
      border-radius: 100px;
      border: 2px solid $lightWhite;
      padding: 25px;

      &::placeholder {
        color: $blackText;
        opacity: 0.5;
      }
    }

    .filter__button {
      background-color: $orange;
      border-radius: 100px;
      border: none;
      width: 100%;
      height: 100%;
    }
  }
}

@media only screen and (max-width: 767px) {
  .filter-content__data {
    .filter-form {
      .filter-form__title {
        margin-bottom: 24px;
      }

      .filter-form__content {
        .col-sm-12 {
          margin-bottom: 24px;

          .filter__button {
            height: 54px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .filter-content__data {
    .filter-form {
      width: 100%;
    }
  }
}
