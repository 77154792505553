.home {
  background: $lightWhite;
  border-radius: 16px;
  padding: 48px 48px 78px 48px;
  border: 2px solid $lightWhite;

  .home__title {
    font-weight: 300;
    font-size: 48px;
  }

  .home__subtitle {
    font-weight: 300;
    font-size: 20px;
    color: $blackText;
    opacity: 0.75;
  }
}
