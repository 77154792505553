// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Custom Styles files
@import 'styles/fonts';
@import 'styles/colors';
@import 'styles/variables';

// Component Styles
@import 'components/Home/styles';
@import 'components/Loader/styles';
@import 'components/Locations/styles';
@import 'components/Locations/Map/styles';
@import 'components/Locations/CardList/styles';
@import 'components/Locations/CardList/Card/styles';
@import 'components/Locations/Filter/styles';
@import 'components/Locations/Filter/City/styles';
@import 'components/Locations/Filter/State/styles';
@import 'components/Locations/Filter/ZipCode/styles';

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

p {
  text-align: center;
}
