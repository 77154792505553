.map-container {
  margin-top: -30px;
}

.map-container,
.loader-container,
.map-container__map {
  border-radius: $border-radius-16;
  height: 500px;
  width: 100%;

  .marker {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    background-color: #000;
    border: 2px solid #fff;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
    &:hover {
      z-index: 1;
    }
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: $white;
}
